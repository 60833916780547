import { useMemo } from 'react';
import { css } from 'emotion';
import { CircularProgress } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { PDFPage } from './PDFPage';
import { usePDF } from '../../api-hooks/usePDF';
import { CustomAxios } from '../../axios/axios';
import { theme } from '../../mui-theme';
import { Div } from '../../shared-components';
import { Viewer } from '../brite-viewer-v2/ViewerProvider';
import { useStore } from '../store-provider/use-store';
import { PDFPageV2 } from './PDFPageV2';

export const PDFViewer = ({ guideId, pageId }) => {
  const { pages, loading } = usePDF({ guideId });

  const {
    data: { displaySettings },
  } = useStore();

  // * If pageId is provided, render only that page, else render all pages.
  const pagesList = useMemo(() => {
    if (pageId) {
      return pages?.filter((page) => page.ID === pageId);
    }
    return pages;
  }, [pageId, pages, loading]);

  if (pages?.length === 0) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <Div
        css={css`
          background: white;
          height: 100%;
          font-family: 'Roboto', 'Montserrat', sans-serif;
          overflow: visible;
        `}
      >
        <Div
          css={css`
            display: flex;
            flex-direction: column;
            height: 100%;
          `}
        >
          {loading ? (
            <Div
              css={css`
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <CircularProgress />
            </Div>
          ) : (
            <main
              id="main-content"
              className={css`
                flex: auto;
                overflow-y: visible;
              `}
            >
              <link
                rel="stylesheet"
                href={`${CustomAxios.baseURL()}public/v1/course/${pages[0]?.CourseID}/theme-css`}
              />
              {pagesList?.map((page, index) =>
                page?.Type === 'brite-editor-v2' ? (
                  <Viewer key={page?.ID} resources={{ displaySettings }} page={page} themeLoaded={true} type="pdf">
                    <PDFPageV2 />
                  </Viewer>
                ) : (
                  <PDFPage page={page} key={index} />
                )
              )}
            </main>
          )}
        </Div>
      </Div>
    </ThemeProvider>
  );
};
