import { useMemo } from 'react';
import { BenefitSummary } from './summaries/benefit-summary';
import { PricingSummary } from './summaries/pricing-summary';
import { NetworkSearch } from './summaries/network-search';
import { useProduct } from './use-product';

export const Benefits = ({ item }) => {
  const { component, data, type } = item;
  const product = useProduct({
    productId: data?.id,
    productType: type,
  });

  const Summary = useMemo(() => {
    if (component === 'benefits') {
      return <BenefitSummary  data={product} column={item} />;
    } else if (component === 'pricing') {
      const premiumsSetExternally = product?.product?.Details?.PlanType?.startsWith('aca');
      const props = {
        data: product,
        summary: data,
        type: type,
        premiumsSetExternally,
      };
      return <PricingSummary column={item} {...props} />;
    } else if (component === 'network') {
      return <NetworkSearch column={item} data={product} summary={data} type={type} />;
    }
  }, [data?.id, data?.variant, product]);

  if (!data?.id) {
    return null;
  } else {
    return Summary;
  }
};
