import { useViewer, Viewer } from '../../brite-viewer-v2/ViewerProvider';
import { getAttributes } from '../../brite-viewer/utils';
import { PDFComponent } from '../PDFComponent';

export const PDFBlock = ({ id }) => {
  const viewer = useViewer();
  const parentBlock = Viewer.getBlock(viewer, id);
  const childCount = parentBlock?.children?.length;

  const childBlock = Viewer.getBlock(viewer, id);

  const { properties, ...rest } = childBlock;
  const data = {
    ...rest,
    ...properties,
    component: rest?.type,
  };

  const containerAttributes = getAttributes({
    column: data,
    length: childCount,
    type: 'container',
  });

  const style = {
    ...containerAttributes.style,
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
  };

  const props = { item: data, totalColumns: childCount, containerAttributes };

  return (
    <div {...containerAttributes} style={style}>
      <PDFComponent {...props} />
    </div>
  );
};
