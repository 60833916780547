import { Box } from '../../shared-components/Box';
import { Row } from './Row';
import { useViewer, Viewer } from './ViewerProvider';

const getStyles = (root, properties) => {
  const { backgroundColor = '', ...styles } = properties?.style || {};
  const isFullScreen = properties?.fullScreenWidth;

  const bgWidth = isFullScreen
    ? { width: '100%', maxWidth: '100%', minWidth: '100%' }
    : {
        width: '100%',
        maxWidth: root?.style?.maxWidth,
        minWidth: root?.style?.minWidth,
      };

  const background = {
    backgroundColor: backgroundColor || 'rgba(0, 0, 0, 0)',
    ...bgWidth,
    ...(styles || {}),
  };

  return {
    container: isFullScreen ? styles : {},
    section: { ...root?.style, ...(!isFullScreen ? styles : {}) },
    background,
  };
};

const getRowClasslist = (properties) => {
  // const hasBenefits = data?.columns?.some((column) => {
  //   if (column.type === 'list') {
  //     return data?.list?.some((item) => item.component === 'benefits');
  //   }
  //   return column?.component === 'benefits';
  // });
  let classList = ['row'];

  if (properties?.hideOnMobile && properties?.hideOnDesktop) {
    classList.push('non-viewable');
  } else if (properties?.hideOnMobile) {
    classList.push('desktop-only');
  } else if (properties?.hideOnDesktop) {
    classList.push('mobile-only');
  }

  // if (properties?.stackColumns || hasBenefits) {
  //   classList.push('stack-columns');
  // }

  return classList.join(' ');
};

export const Section = ({ id }) => {
  const viewer = useViewer();
  const root = Viewer.getRoot(viewer);
  const block = Viewer.getBlock(viewer, id);
  const styles = getStyles(root, block?.properties);
  const classes = getRowClasslist(block?.properties);
  return (
    <Box
      flex="center"
      className={classes}
      css={`
        position: relative;
        box-sizing: border-box;
        a {
          color: inherit;
          text-decoration: inherit;
        }
      `}
      style={styles.container}
    >
      <Box
        css={`
          position: absolute;
          top: 0;
          bottom: 0;
          margin: 0 auto;
          z-index: 0;
          pointer-events: none;
          box-sizing: border-box;
        `}
        style={styles.background}
      />
      <Box
        style={styles.section}
        css={`
          z-index: 1;
          box-sizing: border-box;
        `}
      >
        {block?.children?.map((child) => (
          <Row key={child?.id} id={child?.id} />
        ))}
      </Box>
    </Box>
  );
};
