import { createContext, useContext } from 'react';

const ViewerProviderContext = createContext();

export const useViewer = () => useContext(ViewerProviderContext);

export const Viewer = ({ resources, page, themeLoaded, children, type }) => {
  return (
    <ViewerProviderContext.Provider value={{ resources, page, themeLoaded, type }}>
      {children}
    </ViewerProviderContext.Provider>
  );
};

Viewer.getBlock = (viewer, id) => {
  if (id in viewer?.page?.Content?.data) {
    return viewer?.page?.Content?.data[id];
  } else {
    const section = viewer?.page?.Content?.sections?.find((section) => section.id === id);
    if (section) {
      return section;
    }
  }
  return {};
};

Viewer.getAllChildren = (viewer, id) => {
  let list = [];
  const block = Viewer.getBlock(viewer, id);

  if (block?.children) {
    list = [...block.children];
    for (const child of block.children) {
      const children = Viewer.getAllChildren(viewer, child.id);
      list = [...list, ...children];
    }
  }

  return list;
};

Viewer.getParentBlock = (viewer, id) => {
  const block = Viewer.getBlock(viewer, id);
  return Viewer.getBlock(viewer, block?.parentId);
};
Viewer.getRoot = (viewer) => viewer?.page?.Content?.root || {};
Viewer.getContent = (viewer) => viewer?.page?.Content || {};
Viewer.getResource = (viewer, resource) => viewer?.resources?.[resource] || {};
