import { useMemo } from 'react';
import { Box } from '../../../shared-components/Box';
import { useViewer, Viewer } from '../../brite-viewer-v2/ViewerProvider';
import { PDFRow } from './PDFRow';

const getStyles = (root, properties) => {
  const { backgroundColor = '', ...styles } = properties?.style || {};
  const isFullScreen = properties?.fullScreenWidth;

  const bgWidth = {
    width: '100%',
    maxWidth: '100%',
    minWidth: '100%',
  };

  const background = {
    backgroundColor: backgroundColor || 'rgba(0, 0, 0, 0)',
    ...bgWidth,
    ...(styles || {}),
  };

  return {
    container: isFullScreen ? styles : {},
    section: { ...bgWidth, ...(!isFullScreen ? styles : {}) },
    background,
  };
};

export const PDFSection = ({ id }) => {
  const viewer = useViewer();
  const root = Viewer.getRoot(viewer);
  const block = Viewer.getBlock(viewer, id);
  const styles = getStyles(root, block?.properties);

  const hasBenefits = useMemo(() => {
    const children = Viewer.getAllChildren(viewer, id);
    const hasBenefits = children?.find((child) => child?.type === 'benefits');
    return !!hasBenefits;
  }, []);

  const horizontalMargin = useMemo(() => {
    if (block?.properties?.fullScreenWidth) {
      return '-16px';
    }
    return hasBenefits ? '16px' : '84px';
  });

  if (block?.properties?.hideOnExport) {
    return null;
  }

  return (
    <Box
      flex="center"
      css={`
        position: relative;
        margin: 0 ${horizontalMargin};
        box-sizing: border-box;
        border: none;
        a {
          color: inherit;
          text-decoration: inherit;
        }
      `}
      style={styles.container}
    >
      <Box
        css={`
          position: absolute;
          top: 0;
          bottom: 0;
          margin: 0 auto;
          z-index: 0;
          pointer-events: none;
          box-sizing: border-box;
        `}
        style={styles.background}
      />
      <Box
        style={styles.section}
        css={`
          z-index: 1;
          box-sizing: border-box;
        `}
      >
        {block?.children?.map((child) => (
          <PDFRow key={child?.id} id={child?.id} />
        ))}
      </Box>
    </Box>
  );
};
