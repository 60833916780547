import { css } from 'emotion';
import { useEffect, useState } from 'react';
import { CustomAxios } from '../axios/axios';
import { Div } from '../shared-components';
import { flex } from '../shared-components/shared-styles';
import { BriteEditorViewer } from './brite-viewer/brite-editor-viewer';
import { useDisplaySettings } from './brite-viewer/components/benefits/plan-comparisons/use-display-settings';
import { useStore } from './store-provider/use-store';
import { Viewer } from './brite-viewer-v2/ViewerProvider';
import { BriteViewerV2 } from './brite-viewer-v2/BriteViewerV2';
import { PRODUCT_HEADERS } from './brite-viewer/components/benefits/product-comparisons';

export const TemplateViewer = ({ templateId }) => {
  const { setStore } = useStore();

  const [themeLoaded, setThemeLoaded] = useState(true);
  const [template, setTemplate] = useState({});
  const fetchTemplate = async () => {
    try {
      const response = await CustomAxios.get(`/public/v1/templates/${templateId}`);
      setTemplate(response.data);
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    fetchTemplate();
  }, [templateId]);

  const fetchProductLayouts = async (productTypes) => {
    try {
      const types = [...new Set(productTypes)];
      const promises = await Promise.allSettled(
        types.map((item) => CustomAxios.get(`/public/v1/product-layout/${item}`))
      );
      const layouts = promises.reduce((prev, item, idx) => {
        if (item.status === 'fulfilled') {
          return { ...prev, [types[idx]]: item.value.data };
        }
      }, {});
      setStore('productLayouts', layouts);
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    const types = Object.keys(PRODUCT_HEADERS);
    fetchProductLayouts(types);
  }, [template]);

  useDisplaySettings(template.BusinessID, '', (displaySettings) => setStore('displaySettings', displaySettings));
  const {
    data: { displaySettings = {} },
  } = useStore();

  return (
    <Div
      css={css`
        ${flex('center start')} width: 100%;
        background-color: white;
      `}
    >
      <Div
        css={css`
          overflow: hidden;
        `}
      >
        <link
          rel="stylesheet"
          href={`${CustomAxios.baseURL()}public/v1/course/0c601b45-ac30-4b5a-9099-3c02f073f31a/theme-css`}
          onLoad={() => setThemeLoaded(true)}
          onLoadStart={() => setThemeLoaded(false)}
        />
        {template?.Type === 'brite-editor' ? (
          <BriteEditorViewer
            page={{ Content: template.Content }}
            themeLoaded={themeLoaded}
            displaySettings={displaySettings}
          />
        ) : template?.Type === 'brite-editor-v2' ? (
          <Viewer resources={{}} page={template} themeLoaded={themeLoaded} type="content">
            <BriteViewerV2 />
          </Viewer>
        ) : null}
      </Div>
    </Div>
  );
};
