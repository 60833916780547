import { Box } from '../../../shared-components/Box';
import { useViewer, Viewer } from '../../brite-viewer-v2/ViewerProvider';
import { PDFColumn } from './PDFColumn';

export const PDFRow = ({ id }) => {
  const viewer = useViewer();
  const block = Viewer.getBlock(viewer, id);
  const stackClass = block?.properties?.stackColumns ? 'stack-columns' : '';
  return (
    <Box
      flex="stretch"
      className={`row ${stackClass}`}
      css={`
        width: 100%;
        box-sizing: border-box;
      `}
    >
      {block?.children?.map((child) => (
        <PDFColumn id={child?.id} key={child?.id} />
      ))}
    </Box>
  );
};
