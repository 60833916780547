import { Box } from '../../shared-components/Box';
import { Column } from './Column';
import { useViewer, Viewer } from './ViewerProvider';

export const Row = ({ id }) => {
  const viewer = useViewer();
  const block = Viewer.getBlock(viewer, id);
  const classes = block?.properties?.stackColumns ? 'stack-columns' : '';
  return (
    <Box
      flex="stretch"
      className={classes}
      css={`
        width: 100%;
      `}
    >
      {block?.children?.map((child) => (
        <Column id={child?.id} key={child?.id} />
      ))}
    </Box>
  );
};
