import { Box } from '../../shared-components/Box';
import { Block } from './Block';
import { useViewer, Viewer } from './ViewerProvider';

export const Column = ({ id }) => {
  const viewer = useViewer();
  const block = Viewer.getBlock(viewer, id);

  return (
    <Box
      css={`
        width: 100%;
        flex-grow: 1;
        box-sizing: border-box;
      `}
      flex="column start"
    >
      {block?.children?.map((child) => (
        <Block id={child?.id} type={child.type} key={child?.id} />
      ))}
    </Box>
  );
};
