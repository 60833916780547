import React, { useState, useEffect, useRef, useMemo } from 'react';
import { css } from 'emotion';
import MenuDrawer from './menu-drawer';
import Drawer from '@material-ui/core/Drawer';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { RegularPageViewer } from './regular-page-viewer';
import { QuestionPageViewer } from './question-page-viewer';
import { DecisionToolDecider } from './decision-tool-views/decision-tool-decider';
import SearchModal from './search-modal';
import KeyboardEventHandler from '@pervanovo/react-keyboard-event-handler';
import { CTESection } from './cte-section';

import { CustomAxios } from '../axios/axios';
import { ShareModal } from '../components/share-modal/share-modal';
import SearchDesktop from '../components/seach-desktop';
import { useStoredRecs } from '../api-hooks/use-stored-recs';
import { BriteLanguageSelector } from './languages';

import { List as ListIcon, X, FirstAidKit, MagnifyingGlass, HandWaving, ArrowRight } from 'phosphor-react';
import { BriteEditorViewer } from './brite-viewer/brite-editor-viewer';
import { useKeyboard } from '../lib/use-keyboard';

import { posthogAttrs } from '../posthog-constants';
import { useStore } from './store-provider/use-store';
import { useLocalStorage } from '../api-hooks/use-local-storage';
import { BriteViewerV2 } from './brite-viewer-v2/BriteViewerV2';
import { Viewer } from './brite-viewer-v2/ViewerProvider';

export const MobileView = (props) => {
  const {
    originalRecs,
    userSelectedRecs,
    userAnswers,
    clearStoredRecs,
    openRecResults,
    setRecId,
    recId,
    setUserSelectedRecs,
    setOriginalRecs,
    setUserAnswers,
  } = useStoredRecs();
  const briteLocalStorage = useLocalStorage();

  const {
    data: { decisionTool = '' },
    setStore,
  } = useStore();
  const setDecisionTool = (value) => setStore('decisionTool', value ? 'open' : '');

  const [themeLoaded, setThemeLoaded] = useState(false);
  const ref = useRef();
  const headerRef = useRef();
  const [navOpen, setNavOpen] = useState(false);
  const [searchDesktopOpen, setSearchDesktopOpen] = useState(false);
  const [oldPageIndex, setOldPageIndex] = useState(-1);

  const updateView = () => {
    props.setHeaderHeight(headerRef?.current?.clientHeight || 0);
    props.setContainerHeight(ref?.current?.clientHeight || 0);
  };

  useEffect(() => {
    updateView();

    window.addEventListener('resize', updateView);
    return function cleanup() {
      window.removeEventListener('resize', updateView);
    };
  }, [headerRef]);

  useEffect(() => {
    if (openRecResults) {
      setOldPageIndex(-1);
      goToDecisionToolPage();
      setDecisionTool(true);
    }
  }, [openRecResults]);

  const [chatbotEnabled, setChatbotEnabled] = useState(() => {
    // getting stored value
    const saved = briteLocalStorage.getItem('chatbotEnabled');
    const initialValue = JSON.parse(saved);
    return initialValue || false;
  });

  const executeScroll = () => (ref.current.scrollTop = 0);

  useEffect(() => {
    briteLocalStorage.setItem('chatbotEnabled', JSON.stringify(chatbotEnabled));
  }, [chatbotEnabled]);

  useKeyboard({
    'alt+ctrl+shift+KeyM': () => {
      setChatbotEnabled(!chatbotEnabled);
    },
  });

  useEffect(() => {
    setTimeout(() => setThemeLoaded(true), 2000);
  }, []);

  const decisionToolPageIsAvailable = () => {
    for (let page of props.pages) {
      if (page.Type === 'decision') {
        return true;
      }
    }
    return false;
  };

  const goToDecisionToolPage = () => {
    for (let i = 0; i < props.pages.length; i++) {
      if (props.pages[i].Type === 'decision') {
        props.setCurrentPageIndex(i);
      }
    }
  };

  const goToFirstPage = () => {
    props.setCurrentPageIndex(0);
  };

  const getUserSelectedRecCount = () => {
    if (userSelectedRecs?.selectedBenefits) {
      return userSelectedRecs?.selectedBenefits?.length;
    }
    return 0;
  };

  const editorViewer = useMemo(() => {
    return (
      <BriteEditorViewer
        page={props.currentPage}
        themeLoaded={themeLoaded}
        key={props.currentPageIndex}
        updateContentPosition={props.updateContentPosition}
      />
    );
  }, [themeLoaded, props.currentPageIndex, props.updateContentPosition, props.currentPage?.ID]);

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        height: 100%;
      `}
    >
      <header
        className={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: ${props.isDesktop ? '16px' : '4px'};
          max-height: 80px;
          border-bottom: 1px solid #e8edf3;
        `}
        ref={headerRef}
      >
        {/* We only pull the theme CSS if the current page gives us the CourseID--the Feedback Page does not have this property so it will not get the course theme right now. That might be what we want anyways */}
        {props.pages[props.currentPageIndex].CourseID ? (
          <link
            rel="stylesheet"
            href={`${CustomAxios.baseURL()}public/v1/course/${props.pages[props.currentPageIndex].CourseID}/theme-css`}
            onLoad={() => setThemeLoaded(true)}
            onLoadStart={() => setThemeLoaded(false)}
          />
        ) : null}
        <div
          className={css`
            display: flex;
            align-items: center;
            cursor: pointer;
          `}
          onClick={() => {
            goToFirstPage();
          }}
        >
          {props.logoUrl && props.isDesktop && (
            <img
              alt="Logo"
              src={props.logoUrl}
              className={css`
                max-height: 54px;
              `}
            />
          )}

          {props.logoUrl && !props.isDesktop && (
            <img
              alt="Logo"
              src={props.logoUrl}
              className={css`
                padding-left: 12px;

                max-height: 32px;
              `}
            />
          )}
        </div>

        {searchDesktopOpen && (
          <>
            <SearchDesktop
              courseId={props.currentPage?.CourseID}
              pages={props.pages}
              setCurrentPageIndex={props.setCurrentPageIndex}
              setSearchDesktopOpen={setSearchDesktopOpen}
            />
          </>
        )}

        <div
          className={css`
            display: flex;
            align-items: center;
            gap: 4px;
          `}
        >
          {decisionToolPageIsAvailable() && !searchDesktopOpen && (
            <>
              {props.isDesktop ? (
                <div
                  data-ph-capture-attribute-source={posthogAttrs.types.NAVIGATION}
                  data-ph-capture-attribute-action={posthogAttrs.actions.GET_RECOMMENDATIONS}
                  onClick={() => {
                    setOldPageIndex(props.currentPageIndex);
                    goToDecisionToolPage();
                    setDecisionTool(true);
                  }}
                  className={css`
                    box-shadow: 0px 2px 8px rgba(37, 40, 45, 0.25);
                    border-radius: 51px;
                    padding: 6px;
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    cursor: pointer;
                    border: 2px solid transparent;
                    &:hover {
                      border: 2px solid
                        ${props.designStyles?.Theme?.Body?.ButtonColor
                          ? props.designStyles?.Theme?.Body?.ButtonColor
                          : '#25282D'};
                    }
                  `}
                >
                  <div
                    className={css`
                      background: ${props.designStyles?.Theme?.Body?.ButtonColor
                        ? props.designStyles?.Theme?.Body?.ButtonColor
                        : '#25282D'};
                      padding: 8px;
                      border-radius: 51px;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    `}
                  >
                    <FirstAidKit color="white" size="24" />
                  </div>
                  <div
                    className={css`
                      font-family: 'Roboto';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 14px;
                      line-height: 24px;
                      /* identical to box height, or 171% */

                      color: #25282d;
                      padding-right: 12px;
                    `}
                  >
                    {getUserSelectedRecCount() > 0
                      ? `${getUserSelectedRecCount()} Benefit${getUserSelectedRecCount() > 1 ? 's' : ''}`
                      : 'Get Recommendations'}
                  </div>
                </div>
              ) : (
                <Tooltip PopperProps={{ style: { zIndex: 234234 } }} title={'Get Recommendations'}>
                  <div
                    onClick={() => {
                      setOldPageIndex(props.currentPageIndex);
                      goToDecisionToolPage();
                      setDecisionTool(true);
                    }}
                    data-ph-capture-attribute-source={posthogAttrs.types.NAVIGATION}
                    data-ph-capture-attribute-action={posthogAttrs.actions.GET_RECOMMENDATIONS}
                    className={css`
                      background: ${props.designStyles?.Theme?.Body?.ButtonColor
                        ? props.designStyles?.Theme?.Body?.ButtonColor
                        : '#25282D'};
                      padding: 8px;
                      border-radius: 51px;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      cursor: pointer;

                      &:hover {
                        opacity: 0.9;
                      }
                    `}
                  >
                    <div
                      className={css`
                        display: flex;
                        gap: 8px;
                        padding: 0 8px;
                      `}
                    >
                      <FirstAidKit color="white" size="24" />
                      {getUserSelectedRecCount() > 0 && (
                        <div
                          className={css`
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 24px;
                            /* identical to box height, or 171% */

                            letter-spacing: 0.15px;

                            color: #ffffff;
                            padding: 0px 4px;
                          `}
                        >
                          {getUserSelectedRecCount()}
                        </div>
                      )}
                    </div>
                  </div>
                </Tooltip>
              )}
            </>
          )}

          {!props.isDesktop && (
            <>
              {!props.isSurvey && (
                <SearchModal
                  courseId={props.currentPage?.CourseID}
                  pages={props.pages}
                  setCurrentPageIndex={props.setCurrentPageIndex}
                />
              )}{' '}
            </>
          )}

          {props.isDesktop && !searchDesktopOpen && (
            <>
              <div>
                <Tooltip PopperProps={{ style: { zIndex: 234234 } }} title={'Search'}>
                  <IconButton
                    data-ph-capture-attribute-source={posthogAttrs.types.NAVIGATION}
                    data-ph-capture-attribute-action={posthogAttrs.actions.SEARCH}
                    color="inherit"
                    aria-label="Search"
                    onClick={() => {
                      setSearchDesktopOpen(true);
                    }}
                    edge="end"
                  >
                    <MagnifyingGlass />
                  </IconButton>
                </Tooltip>
              </div>
            </>
          )}

          <BriteLanguageSelector
            guideID={props.currentPage?.CourseID}
            changeLanguageCallback={props.changeLanguageCallback}
          />

          <ShareModal
            shareData={{
              title: 'Check out our Benefits',
              text: 'Click the link to learn about how our benefits work. ',
              url: window.location.origin + window.location.pathname,
            }}
            designStyles={props.designStyles}
          />

          <div
            className={css`
              padding-right: 16px;
            `}
          >
            <Tooltip PopperProps={{ style: { zIndex: 234234 } }} title={'Menu'}>
              <IconButton
                id="navigation"
                color="inherit"
                aria-label="Toggle Navigation Menu"
                edge="end"
                data-ph-capture-attribute-source={posthogAttrs.types.NAVIGATION}
                data-ph-capture-attribute-action={posthogAttrs.actions.PAGES_MENU}
                onClick={() => setNavOpen(!navOpen)}
              >
                {navOpen ? <X /> : <ListIcon />}
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </header>

      <main
        id="main-content"
        ref={ref}
        className={css`
          flex: auto;
          overflow-y: auto;
          ${props.isDesktop && navOpen ? `width: calc(100% - 300px);` : ''}
        `}
      >
        {!props.isDesktop && (
          <Drawer anchor={'right'} open={navOpen} onClose={() => setNavOpen(false)} variant={'temporary'}>
            <MenuDrawer
              title={props.pages && props.pages[0] && props.pages[0].ShowGuideName && props.pages[0].CourseName}
              setCurrentPageIndex={props.setCurrentPageIndex}
              pages={props.pages}
              currentPageIndex={props.currentPageIndex}
              isDesktop={props.isDesktop}
              setOpen={setNavOpen}
              open={navOpen}
            />
          </Drawer>
        )}

        {props.isDesktop && navOpen && (
          <div
            className={css`
              width: 300px;
              border-left: 1px solid #d1dae3;
              position: fixed;
              top: 89px;
              right: 0;
              z-index: 1200;
              height: 100%;
              margin-bottom: 100px;
              overflow: auto;
            `}
          >
            <MenuDrawer
              title={props.pages && props.pages[0] && props.pages[0].ShowGuideName && props.pages[0].CourseName}
              setCurrentPageIndex={props.setCurrentPageIndex}
              pages={props.pages}
              currentPageIndex={props.currentPageIndex}
              isDesktop={props.isDesktop}
              setOpen={setNavOpen}
              open={navOpen}
            />
          </div>
        )}

        {props.currentPage && props.currentPage.Type === 'endOfGuideEnroll' && (
          <div
            className={css`
              max-width: 700px;
              margin: 16px auto;
              padding: 24px;
            `}
          >
            <div
              className={css`
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 34px;
                line-height: 48px;
                margin-bottom: 24px;
                /* or 141% */

                color: #25282d;
              `}
            >
              You’ve reached the end of the Benefit Guide
            </div>

            {decisionToolPageIsAvailable() && getUserSelectedRecCount() === 0 && (
              <div
                className={css`
                  background: #f5f7fa;

                  border-radius: 8px;
                  padding: 16px 24px;
                  display: flex;

                  align-items: center;
                  gap: 14px;
                  flex-wrap: wrap;
                  margin-bottom: 24px;
                `}
              >
                <div>
                  <HandWaving
                    color={
                      props.designStyles?.Theme?.Body?.ButtonColor
                        ? props.designStyles?.Theme?.Body?.ButtonColor
                        : '#25282d'
                    }
                  />
                </div>
                <div
                  className={css`
                    font-family: 'Roboto';

                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    /* identical to box height, or 150% */

                    letter-spacing: 0.15px;

                    color: #25282d;
                    flex: 1;
                  `}
                >
                  Don’t forget to get your benefit recommendations
                </div>
                <div
                  className={css`
                    margin: 0 auto;
                  `}
                >
                  <Button
                    style={{
                      padding: '8px 24px',
                      backgroundColor: props.designStyles?.Theme?.Body?.ButtonColor
                        ? props.designStyles?.Theme?.Body?.ButtonColor
                        : '#25282d',
                      color: '#fff',
                      width: '234px',
                    }}
                    id="footer"
                    variant="contained"
                    role="nav"
                    className="page-nav-next"
                    onClick={() => {
                      goToDecisionToolPage();
                      setDecisionTool(true);
                    }}
                  >
                    Get Recommendations
                  </Button>
                </div>
              </div>
            )}

            {props.currentPage.ShowFeedbackPage && (
              <CTESection
                color={
                  props.designStyles?.Theme?.Body?.ButtonColor
                    ? props.designStyles?.Theme?.Body?.ButtonColor
                    : '#25282D'
                }
                forceShowThankYou={!props.currentPage.EnrollmentURL && !decisionToolPageIsAvailable()}
              />
            )}
            {props.currentPage.EnrollmentURL && (
              <div
                className={css`
                  border: 2px solid #25282d;
                  border-radius: 8px;
                  display: flex;
                  gap: 24px;
                  padding: 24px;
                  background: white;
                  margin-top: 24px;

                  flex-wrap: wrap;
                  align-items: center;
                  justify-content: center;
                `}
              >
                <div>
                  <div
                    className={css`
                  color: #25282D;

                  text-align: center;
                  font-feature-settings: 'clig' off, 'liga' off;
                  /* H2 */
                  font-family: Roboto;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 32px; /
                  `}
                  >
                    Ready To Enroll in Your Benefits?
                  </div>
                  <div
                    className={css`
                      font-family: 'Roboto';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 24px;
                      /* or 150% */

                      color: #66737f;
                      margin-top: 8px;
                      margin-bottom: 16px;
                      max-width: 510px;
                      text-align: center;
                    `}
                  >
                    You will be enrolling online. The link has been provided by your employer and will open in a new tab
                  </div>
                </div>
                <div
                  className={css`
                    border: 1px solid #d1dae3;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    padding: 24px;
                    gap: 24px;
                    justify-content: center;
                    flex-wrap: wrap;
                    @media (max-width: 400px) {
                      border: 1px solid transparent;
                    }
                  `}
                >
                  {props.currentPage.EnrollmentLogoURL && (
                    <div
                      className={css`
                        max-width: 250px;
                        width: 250px;

                        box-sizing: border-box;

                        overflow-wrap: break-word;
                      `}
                    >
                      <img
                        src={props.currentPage.EnrollmentLogoURL}
                        className={css`
                          max-width: 100%;
                          max-height: 100%;
                        `}
                      />
                    </div>
                  )}
                  <div>
                    <Button
                      style={{
                        padding: '16px 24px',
                        width: '282px',
                        backgroundColor: `${
                          props.designStyles?.Theme?.Body?.ButtonColor
                            ? props.designStyles?.Theme?.Body?.ButtonColor
                            : '#25282D'
                        }`,
                      }}
                      onClick={() => {
                        window.open(props.currentPage.EnrollmentURL, '_blank');
                      }}
                      color="primary"
                      variant="contained"
                      size="large"
                      endIcon={<ArrowRight size={24} color="#fff" />}
                      data-ph-capture-attribute-source={posthogAttrs.types.NAVIGATION}
                      data-ph-capture-attribute-action={posthogAttrs.actions.ENROLL}
                    >
                      Enroll Now
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {decisionToolPageIsAvailable() && getUserSelectedRecCount() > 0 && (
              <div
                className={css`
                  background: #f5f7fa;

                  border-radius: 8px;
                  padding: 16px 24px;
                  display: flex;

                  align-items: center;
                  gap: 14px;
                  flex-wrap: wrap;
                  margin-top: 24px;
                `}
              >
                <div>
                  <HandWaving
                    size={32}
                    color={
                      props.designStyles?.Theme?.Body?.ButtonColor
                        ? props.designStyles?.Theme?.Body?.ButtonColor
                        : '#25282d'
                    }
                  />
                </div>
                <div
                  className={css`
                    font-family: 'Roboto';

                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    /* identical to box height, or 150% */

                    letter-spacing: 0.15px;

                    color: #25282d;
                    flex: 1;
                  `}
                >
                  Refer to Your Recommendations while Enrolling
                </div>
                <div
                  className={css`
                    margin: 0 auto;
                  `}
                >
                  <Button
                    style={{
                      padding: '8px 24px',
                      backgroundColor: props.designStyles?.Theme?.Body?.ButtonColor
                        ? props.designStyles?.Theme?.Body?.ButtonColor
                        : '#25282d',
                      color: '#fff',
                      width: '234px',
                    }}
                    id="footer"
                    variant="contained"
                    role="nav"
                    className="page-nav-next"
                    onClick={() => {
                      goToDecisionToolPage();
                      setDecisionTool(true);
                    }}
                  >
                    View Recommendations
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}

        {/* With a little more work we could probably include the DecisionToolViewer page and QuestionPageViewer in the themeable content so that it matches the customers branding, but I'm not going to worry about that right now. */}
        {props.currentPage && props.currentPage.Type === 'regular' && (
          <div className="themeable-content">
            <>
              <RegularPageViewer currentPage={props.currentPage} handlePageHTMLLoaded={props.handlePageHTMLLoaded} />
            </>
          </div>
        )}

        {props.currentPage &&
          props.currentPage.Type === 'brite-editor' &&
          // useMemo around the BriteEditorViewer component to prevent it from rerendering on every content position change from the parent.
          editorViewer}

        {props?.currentPage?.Type === 'brite-editor-v2' ? (
          <Viewer resources={{}} page={props.currentPage} themeLoaded={themeLoaded} type="content">
            <BriteViewerV2 />
          </Viewer>
        ) : null}

        {((props.currentPage && props.currentPage.Type === 'decision') || decisionTool) && (
          <DecisionToolDecider
            setCurrentPageIndex={props.setCurrentPageIndex}
            logoUrl={props.logoUrl}
            goToNextPage={props.nextPage}
            currentPage={props.currentPage}
            pages={props.pages}
            restartRecommendation={props.restartRecommendation}
            restartRecommendationFunction={props.restartRecommendationFunction}
            isDesktop={props.isDesktop}
            isAcaPlan={props.isAcaPlan}
            isIchraPlan={props.isIchraPlan}
            promptOptions={props.promptOptions}
            isSurvey={props.isSurvey}
            customQuestions={props.customQuestions}
            dynamicQuestions={props.dynamicQuestions}
            setOnRecommendationListPage={props.setOnRecommendationListPage}
            onRecommendationListPage={props.onRecommendationListPage}
            showRecActionModal={props.showRecActionModal}
            setShowRecActionModal={props.setShowRecActionModal}
            displaySettings={props.displaySettings}
            setDecisionToolOpen={setDecisionTool}
            decisionTool={decisionTool}
            designStyles={props.designStyles}
            clearStoredRecs={clearStoredRecs}
            originalRecs={originalRecs}
            userSelectedRecs={userSelectedRecs}
            userAnswers={userAnswers}
            setUserAnswers={setUserAnswers}
            openRecResults={openRecResults}
            setUserSelectedRecs={setUserSelectedRecs}
            setOriginalRecs={setOriginalRecs}
            recId={recId}
            setRecId={setRecId}
            oldPageIndex={oldPageIndex}
            changeLanguageCallback={props.changeLanguageCallback}
          />
        )}

        {props.currentPage && props.currentPage.Type === 'question' && (
          <QuestionPageViewer currentPage={props.currentPage} nextPage={props.nextPage} />
        )}
      </main>

      {!props.hideNavigation && (
        <>
          <footer
            className={css`
              height: 80px;
              width: 100%;
              background: white;
              border-top: 1px solid #e8edf3;
              display: flex;
              justify-content: space-between;
              align-items: center;
              min-height: 80px;
              gap: 12px;
              z-index: 1300;
            `}
          >
            <Button
              style={{
                padding: '12px 42px',
                marginLeft: '32px',
                width: '156px',
              }}
              role="nav"
              variant="outlined"
              aria-label="Go to previous page"
              className="page-nav-prev"
              data-ph-capture-attribute-source={posthogAttrs.types.NAVIGATION}
              data-ph-capture-attribute-action={posthogAttrs.actions.PREV_PAGE}
              onClick={() => {
                executeScroll();
                props.prevPage();
              }}
              disabled={props.disablePrevPage()}
            >
              Back
            </Button>

            <Button
              style={{
                padding: '12px 42px',
                backgroundColor: props.disableNextPage()
                  ? '#D1DAE3'
                  : props.designStyles?.Theme?.Body?.ButtonColor
                  ? props.designStyles?.Theme?.Body?.ButtonColor
                  : '#25282D',
                color: '#fff',
                marginRight: '32px',
                width: '156px',
              }}
              id="footer"
              variant="contained"
              role="nav"
              className="page-nav-next"
              data-ph-capture-attribute-source={posthogAttrs.types.NAVIGATION}
              data-ph-capture-attribute-action={posthogAttrs.actions.NEXT_PAGE}
              onClick={() => {
                executeScroll();
                props.nextPage();
              }}
              disabled={props.disableNextPage()}
              aria-label="Go to next page"
            >
              Next
            </Button>
          </footer>
          <KeyboardEventHandler
            handleKeys={['space', 'left', 'right', 'up', 'down', 'enter']}
            onKeyEvent={(key, e) => {
              if (key === 'space' || key === 'right' || key === 'up' || key === 'enter') {
                if (!props.disableNextPage()) {
                  props.nextPage();
                }
              }
              if (key === 'left' || key === 'down') {
                if (!props.disablePrevPage()) {
                  props.prevPage();
                }
              }
            }}
          />{' '}
        </>
      )}
    </div>
  );
};
