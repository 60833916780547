import { Box } from '../../shared-components/Box';
import { useViewer, Viewer } from '../brite-viewer-v2/ViewerProvider';
import { PDFSection } from './PDFComposition/PDFSection';

const briteContentStyles = `
    box-sizing: border-box;
    margin: 0 auto;
    width: ${window.innerWidth}px;
    opacity: 100%;

    .row {
      z-index: 5;
      page-break-inside: avoid;
      a {
        color: inherit;
        text-decoration: inherit;
      }
    }

  `;

export const PDFPageV2 = () => {
  const viewer = useViewer();

  const displaySettings = Viewer.getResource(viewer, 'displaySettings');
  const content = Viewer.getContent(viewer);

  if (!content || !displaySettings) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: 'var(--background-color)',
        breakAfter: 'page',
        ...(content?.root?.containerStyle || {}), // Responsible for the background image.
      }}
    >
      <Box className="_brite-content" css={briteContentStyles}>
        {content?.sections?.map((section) => (
          <PDFSection key={section.id} id={section.id} />
        ))}
      </Box>
    </div>
  );
};
