import React from 'react';
import { getAttributes } from '../utils';
import { css } from 'emotion';

export const getNumber = (value) => {
  try {
    return parseInt(value.replace(/\D/g, ''), 10) || 0;
  } catch {
    return 0;
  }
};
const defaultLineStyles = {
  borderTopColor: '#5b6c74',
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
  borderRadius: '0px',
  width: '100%',
  height: '0',
};

export const Space = ({ item, totalColumns, containerAttributes }) => {
  const componentAttributes = getAttributes({
    column: { ...item, component: 'space' },
    length: totalColumns,
    type: 'component',
  });

  const { style: componentStyles, ...rest } = componentAttributes;

  const { borderTopColor, borderTopStyle, borderTopWidth, ...styles } = componentStyles;

  const deprecatedLineStyles = item?.component === 'line' ? defaultLineStyles : {};

  const lineStyle = {
    ...deprecatedLineStyles,
    borderTopColor: borderTopColor ?? deprecatedLineStyles?.borderTopColor,
    borderTopStyle: borderTopStyle ?? deprecatedLineStyles?.borderTopStyle,
    borderTopWidth: borderTopWidth ?? deprecatedLineStyles?.borderTopWidth,
  };

  const weight = getNumber(borderTopWidth);

  const containerStyle = {
    borderTopRightRadius: containerAttributes?.style?.borderTopRightRadius || '0',
    borderTopLeftRadius: containerAttributes?.style?.borderTopLeftRadius || '0',
    borderBottomRightRadius: containerAttributes?.style?.borderBottomRightRadius || '0',
    borderBottomLeftRadius: containerAttributes?.style?.borderBottomLeftRadius || '0',
  };

  const componentStyle = { position: 'relative', ...styles, ...containerStyle };

  return (
    <div {...rest} style={componentStyle}>
      <div
        className={css`
          position: absolute;
          top: calc(50% - ${weight / 2}px);
          width: 100%;
          z-index: 100;
        `}
        style={lineStyle}
      />
    </div>
  );
};
