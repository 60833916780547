import { posthogAttrs } from '../../posthog-constants';
import { Box } from '../../shared-components/Box';
import { animation } from '../../shared-components/shared-styles';
import { getMobileOperatingSystem } from '../brite-viewer/components/handle-action';
import { Section } from './Section';
import { useViewer, Viewer } from './ViewerProvider';

const hideMobile = `
  .mobile-only {
    display: none !important;
  }
`;

const hideDesktop = `
  .desktop-only {
    display: none !important;
  }
`;

const briteContentStyles = (os) => {
  return `
    box-sizing: border-box;
    margin: 0 auto;
    overflow-x: visible;

    @media only screen and (min-width: 420px) {
      ${os === 'unknown' && hideMobile}
    }
    @media only screen and (max-width: 420px) {
      .stack-columns {
        flex-direction: column;
        .component-container {
          width: 100%;
        }
      }
      ${os !== 'unknown' ? hideDesktop : ''}
    }

    ${os !== 'unknown' ? hideDesktop : ''}
    ${os === 'unknown' ? hideMobile : ''}

    ${
      os !== 'unknown'
        ? `
    .stack-columns {
      flex-direction: column;
      .component-container {
        width: 100%;
      }
    }
    `
        : ''
    }

    .non-viewable {
      display: none !important;
    }
    opacity: 100%;

    ${animation('fadein', '.35s ease')};
    img {
      ${animation('fadein', '.2s ease')};
    }
  `;
};

export const BriteViewerV2 = () => {
  const viewer = useViewer();

  const content = Viewer.getContent(viewer);

  const os = getMobileOperatingSystem();

  return (
    <Box
      data-ph-capture-attribute-source={posthogAttrs?.types?.CONTENT}
      css={`
        position: sticky;
        top: 0;
        background-color: var(--background-color);
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
      `}
      style={content?.root?.containerStyle || {}}
    >
      <Box className="_brite-content" css={briteContentStyles(os)}>
        {content.sections.map((section) => (
          <Section id={section?.id} key={section?.id} />
        ))}
      </Box>
    </Box>
  );
};
