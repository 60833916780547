import { useState, useEffect, useCallback } from 'react';
import { CroppedImage } from '../../brite-viewer/components/cropped-image';
import { getAttributes } from '../../brite-viewer/utils';

export const Image = ({ item, totalColumns, containerAttributes, containerStyle }) => {
  const [isValidUrl, setIsValidUrl] = useState(false);

  const componentAttributes = getAttributes({
    column: item,
    length: totalColumns,
    type: 'component',
  });

  // Function to validate the image URL.
  const validateImageUrl = useCallback(async () => {
    const imageUrl = componentAttributes?.src;
    if (imageUrl) {
      try {
        const response = await fetch(imageUrl, { method: 'HEAD' });
        setIsValidUrl(response.ok); // Set URL validity based on response.
      } catch {
        setIsValidUrl(false); // Handle fetch errors gracefully.
      }
    }
  }, [componentAttributes?.src]);

  // Validate the image URL whenever the source changes.
  useEffect(() => {
    validateImageUrl();
  }, [validateImageUrl]);

  // If the URL is invalid, render nothing.
  if (!isValidUrl) {
    return null;
  }

  return (
    <div style={containerStyle}>
      {item?.crop ? (
        <CroppedImage
          style={componentAttributes?.style}
          width={containerAttributes?.style?.width}
          crop={item?.crop}
          imageProps={{
            src: componentAttributes?.src,
            alt: componentAttributes?.alt,
          }}
        />
      ) : (
        <img alt="" {...componentAttributes} />
      )}
    </div>
  );
};
