import { Box } from '../../../shared-components/Box';
import { useViewer, Viewer } from '../../brite-viewer-v2/ViewerProvider';
import { PDFBlock } from './PDFBlock';

export const PDFColumn = ({ id }) => {
  const viewer = useViewer();
  const block = Viewer.getBlock(viewer, id);

  return (
    <Box
      css={`
        width: 100%;
        flex-grow: 1;
        box-sizing: border-box;
      `}
      flex="column start"
    >
      {block?.children?.map((child) => (
        <PDFBlock id={child?.id} type={child.type} key={child?.id} />
      ))}
    </Box>
  );
};
