import { X } from 'phosphor-react';
import { Button, Modal, Text } from '../../../shared-components';
import parse from 'html-react-parser';
import { smallScreen } from '../additional-benefits/product-components/shared-styles';
import { SummaryField } from '../../brite-viewer/components/benefits/summary-field';
import { RecommendationFieldList } from '../../../shared-components/product-components/recommendation-field-list';
import { Box } from '../../../shared-components/Box';
import { colors } from '../../../shared-components/styles';
import { useEffect, useMemo, useState } from 'react';
import { container } from '../../../shared-components/shared-styles';
import { getDisplayNumberValue } from '../../brite-viewer/components/benefits/field-utils';
import * as math from 'mathjs';
import { PRODUCT_HEADERS } from '../../brite-viewer/components/benefits/product-comparisons';
import { useStore } from '../../store-provider/use-store';

const detailTitlesByType = {
  long_term_disability: "Here's what's covered",
  short_term_disability: "Here's what's covered",
};

const disclaimer = `*There are other aspects of this benefit to consider, including pricing, coverage limitations, exclusions, reductions and other ways this benefit will or will not pay. Please refer to your plan documents for full coverage.`;

const getAdjustedValueByPeriod = (value, months, period = 12) => {
  if (value === undefined || months === undefined) {
    return 0;
  }
  try {
    const monthly = math.divide(value, months);
    const fullAnnual = math.multiply(monthly, 12);
    const next = math.divide(fullAnnual, period);
    return next;
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const LearnMore = ({ recommendation, data, onClose }) => {
  const store = useStore();

  const { product, layout, config } = data;
  const titleDescriptions = product?.TitleDescriptions?.filter(({ Autogenerated }) => !Autogenerated);

  // *** *** *** *** *** *** *** ***
  // HARD CODED SYMETRA
  const description = useMemo(() => {
    if (store?.data?.businessId === '1d50e446-9de0-4778-95d1-78df660080df') {
      if (product?.Type === 'accident_plan') {
        return 'Did you know that accident insurance can pay you cash benefits to offset the costs of accidental injuries and related medical expenses?';
      } else if (product?.Type === 'critical_illness') {
        return "Your employer offers specified disease insurance to help offset the costs of serious illnesses. If you're diagnosed with one of these conditions after the policy is in effect, you can receive a lump-sum benefit. There are limitations on preexisting conditions; please refer to your enrollment materials for complete details.";
      }
    }
    return layout?.Description;
  }, [store?.data?.businessId, layout?.Description]);
  // *** *** *** *** *** *** *** ***

  const parseDisclaimer = (text) => {
    var exp = /(?:^|[^"'])((ftp|http|https|file):\/\/[\S]+(\b|$))/gi;
    const disclaimer = text?.replace(exp, " <a href='$1' target='_blank'>$1</a>") || '';
    return parse(disclaimer);
  };

  const hasPlanCostsForYear = 'PlanCostsForYear' in recommendation;
  const hideContributions = layout?.HideEmployerPremiumContributions;
  const months = recommendation?.PlanCostsForYear?.MonthsIncluded;
  const premiumInterval = store?.data?.displaySettings?.premiumInterval || 12;

  const [tab, setTab] = useState('details');

  useEffect(() => {
    if (hasPlanCostsForYear) {
      setTab('cost');
    }
  }, []);

  const adjustedValues = useMemo(() => {
    if (!hasPlanCostsForYear) {
      return {};
    }
    // NET COST
    const netCostValue = getAdjustedValueByPeriod(
      recommendation?.PlanCostsForYear?.FinalPlanCost,
      months,
      premiumInterval
    );

    // Employer Contributions
    const contributions = getAdjustedValueByPeriod(
      math.abs(recommendation?.PlanCostsForYear?.FinalPlanCostBreakdown?.EmployerPremiumContribution),
      months,
      premiumInterval
    );

    // Total Display Cost (accounting for employer contributions unless opted out)
    let totalCost = recommendation?.PlanCostsForYear?.FinalPlanCostBreakdown?.TotalPremiumCost;
    if (hideContributions) {
      totalCost += recommendation?.PlanCostsForYear?.FinalPlanCostBreakdown?.EmployerPremiumContribution;
    }
    totalCost = getAdjustedValueByPeriod(totalCost, months, premiumInterval);

    // Out of Pocket Cost (already included in netCost)
    const estimatedOutOfPocketCost = getAdjustedValueByPeriod(
      recommendation?.PlanCostsForYear?.FinalPlanCostBreakdown?.EstimatedOutOfPocketCost,
      months,
      premiumInterval
    );

    // Not Covered Cost
    const estimatedNotCoveredCost = getAdjustedValueByPeriod(
      recommendation?.PlanCostsForYear?.FinalPlanCostBreakdown?.NotCoveredMedicalExpenses,
      months,
      premiumInterval
    );

    const incentives = recommendation?.PlanCostsForYear?.AdditionalCostInfo?.IncentivesList?.reduce((prev, item) => {
      if (item?.ComparableOnly || item?.Value === 0) {
        return prev;
      }
      const adjustedValue = getAdjustedValueByPeriod(item?.Value, months, premiumInterval);
      const valueByPeriod = `$${getDisplayNumberValue(adjustedValue, { decimals: 2, round: false })}`;
      return [
        ...prev,
        {
          ...item,
          valueByPeriod,
        },
      ];
    }, []);

    return {
      netCost: `$${getDisplayNumberValue(netCostValue, { decimals: 2, round: false })}`,
      totalCost: `$${getDisplayNumberValue(totalCost, { decimals: 2, round: false })}`,
      contributions: `$${getDisplayNumberValue(contributions, { decimals: 2, round: false })}`,
      estimatedOOPCost: `$${getDisplayNumberValue(estimatedOutOfPocketCost, { decimals: 2, round: false })}`,
      notCoveredCost:
        estimatedNotCoveredCost != 0
          ? `$${getDisplayNumberValue(estimatedNotCoveredCost, { decimals: 2, round: false })}`
          : null,
      incentives,
    };
  }, [recommendation?.Plan?.ID, hideContributions, hasPlanCostsForYear, months, premiumInterval]);

  const premiumDisplayValue = premiumInterval === 12 ? '/ Month' : '/ Pay Period';

  return (
    <Modal display={true} onClose={onClose}>
      <Box
        css={`
          padding: 32px;
          width: 700px;
          box-sizing: border-box;
          ${smallScreen} {
            margin: auto;
            width: 100%;
            max-width: calc(100vw - 64px);
            padding: 0;
          }
        `}
      >
        <Box flex="space-between">
          <Box>
            <Text h2>{product?.ProductName}</Text>
            <Text>{product?.Details?.NetworkName}</Text>
          </Box>
          <Button styles="icon" onClick={onClose}>
            <X />
          </Button>
        </Box>
        {hasPlanCostsForYear ? (
          <Box
            flex="space-between"
            css={`
              border-bottom: 1px solid ${colors.gray[200]};
              margin-top: 32px;
            `}
          >
            <Box
              css={`
                flex-grow: 1;
                padding: 8px;
                ${tab === 'cost' ? `border-bottom: 3px solid ${colors.black};` : ''}
                margin-bottom: -1px;
                ${container.hover}
              `}
              onClick={() => setTab('cost')}
            >
              <Text
                css={`
                  text-align: center;
                  font-weight: bold;
                `}
                label={tab === 'cost'}
              >
                Plan Cost
              </Text>
            </Box>
            <Box
              css={`
                flex-grow: 1;
                padding: 8px;
                ${tab === 'details' ? `border-bottom: 3px solid ${colors.black};` : ''}

                margin-bottom: -1px;
                ${container.hover}
              `}
              onClick={() => setTab('details')}
            >
              <Text
                css={`
                  text-align: center;
                  font-weight: bold;
                `}
                label={tab === 'details'}
              >
                Plan Coverage
              </Text>
            </Box>
          </Box>
        ) : null}
        {tab === 'details' ? (
          <Box
            css={`
              padding: 16px 0;
              padding-top: 0;
              margin-top: 16px;
              border-bottom: 1px solid ${colors.gray[200]};
              max-height: 40vh;
              ${smallScreen} {
                max-height: calc(100vh - 250px);
              }
              overflow-y: auto;
            `}
            alternatingList
            onClick={() => console.log(layout)}
          >
            <Text
              label
              css={`
                margin-bottom: 16px;
                padding: 0 8px;
              `}
            >
              {description}
            </Text>

            {detailTitlesByType?.[product?.Type] ? (
              <Text
                label
                bold
                css={`
                  margin-bottom: 16px;
                `}
              >
                {detailTitlesByType?.[product?.Type]}
              </Text>
            ) : null}

            <RecommendationFieldList product={product} fields={config?.fields} />

            {product?.Details?.ConditionPercentages?.length > 0 && (
              <>
                <Text
                  label
                  bold
                  css={`
                    padding: 16px 32px;
                  `}
                >
                  Listed Conditions
                </Text>
                {product?.Details?.ConditionPercentages?.map(({ Name }, idx) => (
                  <SummaryField
                    product={product}
                    field={{
                      DisplayValue: Name,
                      State: 'show',
                      Type: 'percent',
                      PropertyChain: `Details.ConditionPercentages[${idx}].Percentage`,
                    }}
                  />
                ))}
              </>
            )}

            {titleDescriptions?.map(({ Title }, idx) => (
              <SummaryField
                product={{ TitleDescriptions: titleDescriptions }}
                field={{
                  DisplayValue: Title,
                  State: 'show',
                  Type: 'text-input',
                  PropertyChain: `TitleDescriptions[${idx}].Description`,
                }}
              />
            ))}

            <Text
              css={`
                font-size: 0.8em;
                margin: 16px 0;
              `}
            >
              {product?.Disclaimer ? (
                <>
                  <p>{parseDisclaimer(product.Disclaimer)}</p>
                </>
              ) : (
                disclaimer
              )}
            </Text>
          </Box>
        ) : (
          <Box
            css={`
              padding: 16px 0;
              padding-top: 0;
              margin-top: 16px;
              width: 700px;
              max-height: 40vh;
              ${smallScreen} {
                max-height: calc(100vh - 250px);
                width: 100%;
              }
              overflow-y: auto;
            `}
          >
            <Box
              css={`
                display: grid;
                width: calc(100% - 64px);
                ${smallScreen} {
                  width: 100%;
                }
                padding: 16px 0;
                padding-bottom: 32px;
                margin-bottom: 16px;
                gap: 16px;
                border-bottom: 1px solid ${colors.gray[300]};
              `}
            >
              <Text h4>Understand Your Costs</Text>
              <Box
                flex="space-between wrap"
                css={`
                  p {
                    min-width: 200px;
                  }
                `}
              >
                <Text label>Total cost of medical plan</Text>
                <Text
                  label
                  bold
                  css={`
                    text-align: right;
                  `}
                >
                  {adjustedValues?.totalCost} <span>{premiumDisplayValue}</span>
                </Text>
              </Box>
              {!hideContributions ? (
                <Box
                  flex="space-between wrap"
                  css={`
                    p {
                      min-width: 200px;
                    }
                  `}
                >
                  <Text label>Total employer contribution</Text>
                  <Text
                    label
                    bold
                    css={`
                      color: ${colors.green[100]};
                      text-align: right;
                    `}
                  >
                    {adjustedValues?.contributions} <span>{premiumDisplayValue}</span>
                  </Text>
                </Box>
              ) : null}
            </Box>
            <Box
              css={`
                display: grid;
                width: calc(100% - 64px);
                padding: 16px 0;
                gap: 16px;
                border-bottom: 1px solid ${colors.gray[300]};
                ${smallScreen} {
                  width: 100%;
                }
              `}
            >
              <Text h4>Understand Your Risks</Text>
              <Box
                flex="space-between wrap"
                css={`
                  p {
                    min-width: 200px;
                  }
                `}
              >
                <Text label>Estimated potential claims costs</Text>
                <Text
                  label
                  bold
                  css={`
                    text-align: right;
                  `}
                >
                  {adjustedValues?.estimatedOOPCost}
                </Text>
              </Box>
              {adjustedValues?.notCoveredCost && (
                <Box
                  flex="space-between wrap"
                  css={`
                    p {
                      min-width: 200px;
                    }
                  `}
                >
                  <Text label>Estimated potential costs that are not covered</Text>
                  <Text
                    label
                    bold
                    css={`
                      text-align: right;
                    `}
                  >
                    {adjustedValues?.notCoveredCost}
                  </Text>
                </Box>
              )}
              {adjustedValues?.incentives?.map((item) => (
                <Box
                  flex="space-between wrap"
                  css={`
                    p {
                      min-width: 200px;
                    }
                  `}
                >
                  <Text label>{PRODUCT_HEADERS[item?.Type]}</Text>
                  <Text
                    label
                    bold
                    css={`
                      color: ${colors.green[100]};
                      text-align: right;
                    `}
                  >
                    + ${getDisplayNumberValue(item?.valueByPeriod, { decimals: 2, round: false })}{' '}
                    <span>{premiumDisplayValue}</span>
                  </Text>
                </Box>
              ))}
            </Box>
            <Box
              flex="space-between wrap"
              css={`
                padding: 32px 0;
                width: calc(100% - 64px);
                ${smallScreen} {
                  width: 100%;
                }
                p {
                  min-width: 200px;
                  text-align: right;
                }
              `}
            >
              <Text h4>Your Total Estimated Cost</Text>
              <Text label bold>
                {adjustedValues?.netCost} <span>{premiumDisplayValue}</span>
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
